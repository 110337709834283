import React from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import TextoTitulo from '../components/TextoTitulo';
import BGImage from '../components/Background'
import { graphql } from 'gatsby';

export const query = graphql`
  query  qContacto{
    image: file(relativePath: { eq: "contactus.jpeg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 900, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Contacto = ({ data }) => {
    
    const bkgImage = data.image.sharp;
    return (
        <Layout>
      <Seo title="Contacto Madtronix Peru" description="Contactar a BestOffers PERU" siteUrl="https://madtronix-peru.com/contacto/" />
      <BGImage ImagenFluid={bkgImage.fluid}  ClaseBG="backgroundC"   >
   
      <div className="servicios" style={{paddingBottom:"16.7rem"}}>
      <div style={{ textAlign:"center",margin:"3rem",borderRadius:"5%" ,maxWidth: "29rem", backgroundColor: "#fff",padding:"2.5rem 1rem" }}>
       <TextoTitulo titulo="Contacto"  color="#000"  />
       <div >
         <p style={{fontSize:"15px"}} className="contacto-parrafo">
              Bestoffersperu@gmail.com
           <br /><br /> 
            (+51) 998800767
           <br /><br />
           Si deseas comunicarte con nosotros por favor utiliza cualquiera de los medios que te mostramos en esta tarjeta de presentación
         </p>
           <a  href="https://api.whatsapp.com/send?phone=51928285568&app=facebook&entry_point=page_cta&fbclid=IwAR2Q1yfyWRPWTG46oU1js-A_RRXjNim9aDAvfN3oKORsN_vS1KV_Q54trS8"
             target="_blank"
             rel="noreferrer" 
             className="card__servicios-boton">Mensaje de Whatsapp</a>
         </div>
       </div>
       </div>
   
      </BGImage>
    </Layout>
    );
};

export default Contacto;