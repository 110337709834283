import React from 'react';

const TextoTitulo = (props) => {
  const titulo=props.titulo;
  var colorLLave="";
  if(props.color) 
  colorLLave=props.color;
  else
  colorLLave="#fff"
    
    return (
      <div className="titulosLlave">
        <div className="titulosLLave__row">
        <div className="titulosLLave__column">
          
        </div>
        <div className="titulosLLave__column">
          <h2
            className="card-title text-center"
            style={{  fontSize: "34px", color:colorLLave,lineHeight:"1em" }}
          >
            <span style={{letterSpacing:"0em"}}>  {titulo}</span>
         
        </h2>
        </div>
        <div className="titulosLLave__column">
          
        </div>
      </div>
      </div>
    );
};

export default TextoTitulo;